import audioImage from "../../assets/NMOdata_860x400_luisteren.jpg";
import printingImage from "../../assets/NMOdata_860x400_lezen.jpg";
import visualImage from "../../assets/NMOdata_860x400_kijken.jpg";
import onlineImage from "../../assets/NMOdata_t860x400_online.jpg";
// import outdoorImage from "../../assets/NMOdata_860x400_buitenreclame.jpg";
import crossMediaImage from "../../assets/NMOdata_860x400_crossmedia.jpg";
import ToolCategory from "~/ts/models/dto/tools/ToolCategory";

export interface CategoryDetails {
  category: ToolCategory;
  name: string;
  icon: string;
  image: string;
  description: string;
  unavailableText: string;
  externalLink?: string;
}

const categories: CategoryDetails[] = [
  {
    category: ToolCategory.AUDIO,
    name: "Luisteren",
    icon: "iconoir:sound-high",
    image: audioImage,
    description: "Luistercijfers radiozenders, Streaming maand- en weekdata, Podcast maanddata",
    unavailableText: "Tijdelijk niet beschikbaar",
  },
  {
    category: ToolCategory.PRINTING,
    name: "Lezen",
    icon: "fluent:reading-mode-mobile-24-regular",
    image: printingImage,
    description: "Bereikscijfers, Oplagecijfers",
    unavailableText: "Tijdelijk niet beschikbaar",
  },
  {
    category: ToolCategory.VISUAL,
    name: "Kijken",
    icon: "ion:tv-outline",
    image: visualImage,
    description: "Dagrapporten, Weekrapporten, Maandrapporten, Jaarrapporten",
    unavailableText: "Binnenkort",
    externalLink: "https://kijkonderzoek.nl/kijkcijfers/tv-kijkcijfers",
  },
  {
    category: ToolCategory.DIGITAL,
    name: "Online",
    icon: "ion:earth-outline",
    image: onlineImage,
    description: "Inzicht in het gebruik van websites en apps.",
    unavailableText: "Binnenkort",
  },
  // {
  //   category: ToolCategory.OUTDOOR,
  //   name: "Buitenreclame",
  //   icon: "ph:park",
  //   image: outdoorImage,
  //   description: "Cijfers uit het nieuwe Buitenreclame onderzoek.",
  //   unavailableText: "Verwacht in 2025",
  // },
  {
    category: ToolCategory.CROSS_MEDIA,
    name: "Crossmedia",
    icon: "iconoir:media-video-list",
    image: crossMediaImage,
    description: "Waar data vanuit luisteren, lezen, kijken, online en buitenreclame samenkomen.",
    unavailableText: "Verwacht in 2025",
  },
];

export default categories;
